import * as React from "react"
import { Container } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container className="my-5">
      <h1>404: Not Found</h1>
      <p>Página não encontrada.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
